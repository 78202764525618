<template>
	<v-card max-width="800" class="mx-auto mt-5" :loading="loadingParent" rounded="xl">
		<v-card-title>{{ $t('companies.redeemCoupon') }}</v-card-title>
		<v-card-text>
			<p>{{ $t('companies.redeemCouponHelp1') }}</p>
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="coupon"
					:label="$t('companies.coupon')"
					:rules="rules.coupon"
					:hint="$t('companies.redeemCouponHelp2')"
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
				/>
			</v-form>
		</v-card-text>

		<v-alert v-show="action.message" :type="action.success ? 'success' : 'error'">
			{{ action.message }}
		</v-alert>

		<v-card-actions>
			<v-btn color="primary" rounded :loading="loading" @click="callRedeemCoupon()">
				{{ $t('companies.redeem') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyCoupons',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			coupon: '',
			rules: {
				coupon: [
					(v) => !!v || this.$t('rules.required'),
					(v) => 0 < v.length <= this.couponMaxLength || this.$t('rules.length', { length: this.couponMaxLength })
				]
			},
			action: {
				success: false,
				message: ''
			}
		}
	},
	computed: {
		couponMaxLength() {
			return 64
		},
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.loading = false
	},
	methods: {
		callRedeemCoupon() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.redeemCoupon({
				companyID: this.company.id,
				coupon: this.coupon
			})
				.then(({ success, response }) => {
					if (success) {
						this.coupon = ''
					}
					this.action = { success, message: response }
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('companies', ['redeemCoupon'])
	}
}
</script>
